<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-4 col-12">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Tìm theo ID:</h6>
                    </div>
                    <div class="col-md col-12">
                        <input
                            @change="getListOrderBuffEye()"
                            class="form-control input-light"
                            type="text"
                            placeholder="Nhập ID cần tìm"
                            required=""
                            v-model="object_id_search"
                        />
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-12">
                <div class="row align-items-center">
                    <div class="col-md-auto col-12">
                        <h6 class="bold mb-0">Số nhật ký hiển thị tối đa:</h6>
                    </div>
                    <div class="col-md col-12">
                        <select @change="getListOrderBuffEye()" v-model="limit" class="custom-select select-light">
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="200">200</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
        <datatable :columns="columnsTableLogs" :rows="data"> </datatable>
    </div>
</template>
<script>
import { listBuffEyeV2 } from "../../../../api/services/facebook"
import datatable from "../../../../components/datatable/datatable"
export default {
    name: "facebook-logs",
    components: {
        datatable
    },
    props: {
        panel: String
    },
    data() {
        return {
            object_id_search: "",
            limit: 100,
            data: [],
            columnsTableLogs: [
                {
                    label: "#",
                    field: "index",
                    html: true
                },
                {
                    label: "Người đăng",
                    field: "username",
                    html: true
                },
                {
                    label: "Link live stream",
                    field: "object_id",
                    fb_link: true
                },
                {
                    label: "Số lượng",
                    field: "quantity",
                    numeric: true
                },
                {
                    label: "Thời gian duy trì",
                    field: "time_buff"
                },
                {
                    label: "Tổng tiền",
                    field: "prices"
                },
                {
                    label: "Thời gian",
                    field: "updated_at",
                    timeago: true
                }
            ]
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        currency() {
            return this.$store.state.site.site.currency
        }
    },
    watch: {
        panel(value) {
            if (value == "logs") {
                this.getListOrderBuffEye()
            }
        }
    },
    methods: {
        getListOrderBuffEye: async function() {
            let data = await listBuffEyeV2(this.limit, this.object_id_search)
            if (data.data.length > 0) {
                let index = 1
                this.data = data.data.map(item => {
                    item.index = index++
                    item.time_buff = item.time_buff ? item.time_buff : 0
                    return item
                })
            }
        }
    }
}
</script>
